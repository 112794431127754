@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

* {
    box-sizing: border-box;
}

body {
    font-family: 'Poppins', sans-serif !important;
}

.App {
    text-align: center;
}

#main-slider .splide__list {
    text-align: center;
}

.splide__slide {
    background-size: contain;
    /* Ensure the entire background image is visible within the container */
    background-repeat: no-repeat;
    /* Prevent background image from repeating */
    background-position: center center;
}

.splide__slide {
    background-size: contain !important;
}

#thumbnail-slider-list .splide__slide::after {
    content: '';
    background-color: #000;
    opacity: 0.6;
    width: 100%;
    height: 100%;
    position: absolute;
}

#thumbnail-slider-list .is-active.splide__slide::after {
    opacity: 0 !important;
}

#thumbnail-slider-list .splide__slide.is-active {
    border: unset !important;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

body nav ul li a.menu-active {
    background: #1976d2 !important;
    color: #fff !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}

.ril__toolbarLeftSide {
    margin-left: auto !important;
    padding-left: 140px !important;
    width: 50% !important;
}